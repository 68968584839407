<template>
    <div style="margin-top: 50px" class="mb-15">
        <div class="container-bg" style="margin-top: 5% !important; width: 700px; margin: auto">
            <div class="container-fg">
                <div style="display: flex; flex-direction: row">
                    <img style="width: 130px; margin-bottom: 1%" src="../../assets/logos/getac-logo.svg" />
                    <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">CREATE RMA</div>
                </div>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <div style="display: flex; flex-direction: row">
                    <div style="display: block; margin: auto; width: 60%">
                        <p class="text-h7 nav-font text-center mt-10" style="display: block; margin: auto"
                            >UPGRADE QUOTE SUCCESSFULLY</p
                        >
                        <h1 style="font-size: 36px; letter-spacing: -0.08rem; margin-bottom: 10%" class="text-center"
                            >SAVED</h1
                        >
                        <div class="quote-info">
                            <v-row>
                                <v-col cols="6" class="text-start">
                                    <v-list-item-subtitle>{{ savedQuote.contact.company }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{
                                        savedQuote.contact.firstName + ' ' + savedQuote.contact.lastName
                                    }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ savedQuote.contact.email }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ savedQuote.contact.phone }}</v-list-item-subtitle>
                                </v-col>
                                <v-col cols="6" class="text-right">
                                    <v-list-item-subtitle>{{ savedQuote.address.line1 }}</v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="savedQuote.address.line2">{{
                                        savedQuote.address.line2
                                    }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{
                                        savedQuote.address.city + ', ' + savedQuote.address.state
                                    }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{
                                        savedQuote.address.country + ', ' + savedQuote.address.postalCode
                                    }}</v-list-item-subtitle>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-list-item>
                                    <v-list-item-content class="align-end text-body-2 field-values">
                                        <base-info-card title="Quote Number" color="primary">
                                            <v-list-item-subtitle> {{ savedQuote.quoteId }}</v-list-item-subtitle>
                                        </base-info-card>
                                        <base-info-card title="Serial Number" color="primary">
                                            <v-list-item-subtitle> {{ savedQuote.serial }}</v-list-item-subtitle>
                                        </base-info-card>
                                        <base-info-card title="Service Request Problem" color="primary">
                                            <v-list-item-subtitle>
                                                {{
                                                    savedQuote.primaryFault + ', ' + savedQuote.problem
                                                }}</v-list-item-subtitle
                                            >
                                        </base-info-card>
                                        <base-info-card title="Service Request Quote Fee" color="primary">
                                            <v-list-item-subtitle> {{ savedQuote.quoteFee }}</v-list-item-subtitle>
                                        </base-info-card>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-row>
                        </div>

                        <br />
                        <v-row class="mt-5">
                            <v-col class="text-center">
                                <v-btn
                                    color="primary"
                                    outlined
                                    class="text-center"
                                    style="margin-bottom: 25%; margin-left: 10px"
                                    @click="printPage"
                                    >Print</v-btn
                                >
                                <v-btn
                                    color="primary"
                                    outlined
                                    class="text-center"
                                    style="margin-bottom: 25%; margin-left: 10px"
                                    @click="goToQuotes"
                                    >View Quotes</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ConfirmQuote',
    props: {
        savedQuote: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        printPage() {
            print()
        },
        goToQuotes() {
            this.$router.push({
                name: 'quotes'
            })
        }
    }
}
</script>
